import { useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { useLazyFetch } from "../hooks";

const PrivateKeyUploader = props => {

  const { settings, setSettings, formClass, headingClass, cellClass, labelClass, buttonClass } = props;

  const [key, setKey] = useState("");

  const [{ loading }, updateKey] = useLazyFetch("/settings/key", {
    method: "POST",
    onCompleted: data => {
      setKey("");
      setSettings(data);
    },
  })

  const onSubmit = e => {
    e.preventDefault();
    const strippedKey = key.replace(/-----BEGIN [A-Z ]+-----/, "").replace(/-----END [A-Z ]+-----/, "").trim();
    updateKey({params: {private_key: strippedKey}});
  }
  
  return (
    <form onSubmit={onSubmit} className={formClass}>
      <div className={headingClass}>Private Key</div>
      <div className="text-sm max-w-md info mb-4">
        Provide the private key you use to access the cluster, in order to submit jobs in your own name.
        This will be stored encrypted.
      </div>
      {settings.ssh_private_key && (
        <div className="text-sm border rounded border-flow-blue-1 bg-flow-gray-2 px-2 py-1 w-fit mb-4">
          <span className="font-medium">Current key</span>
          : <span className="font-mono text-xs text-flow-blue-8">{settings.ssh_private_key}</span>
        </div>
      )}
      <div className="flex flex-col gap-2 max-w-lg">
        <label htmlFor="current" className={labelClass}>Private key</label>
        <textarea
          id="current"
          value={key}
          onChange={e => setKey(e.target.value)}
          className="block bg-flow-gray-2 text-2xs text-flow-blue-7 rounded font-medium px-3 h-64 py-1.5 w-full resize-none"
        />
      </div>
      <Button className={buttonClass} type="submit" loading={loading}>
        Save Private Key
      </Button>
    </form>
  );
};

PrivateKeyUploader.propTypes = {
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
  formClass: PropTypes.string.isRequired,
  headingClass: PropTypes.string.isRequired,
  cellClass: PropTypes.string.isRequired,
  labelClass: PropTypes.string.isRequired,
  buttonClass: PropTypes.string.isRequired,
};

export default PrivateKeyUploader;