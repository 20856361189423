import { useState } from "react";
import { Link } from "react-router-dom";
import AccountInfo from "../components/AccountInfo";
import PasswordForm from "../components/PasswordForm";
import Tabs from "../components/Tabs";
import UserDetailsForm from "../components/UserDetailsForm";
import UserImageForm from "../components/UserImageForm";
import GroupSummaries from "../components/GroupSummaries";
import Base from "./Base";
import { useDocumentTitle, useFetch } from "../hooks";
import PrivateKeyUploader from "../components/PrivateKeyUploader";

const SettingsPage = () => {

  const [tab, setTab] = useState("User Details");
  const [settings, setSettings] = useState(null);

  const { loading } = useFetch("/settings", {
    onCompleted: setSettings
  });

  useDocumentTitle("Settings - Flow");

  if (loading) return <Base loading={true} />;

  const managedGroups = settings.groups.filter(g => g.admins.map(a => a.username).includes(settings.username));
  const otherGroups = settings.groups.filter(g => !g.admins.map(a => a.username).includes(settings.username));

  const showPrivateKeyForm = ["yes", "1", "true"].includes(process.env.REACT_APP_USER_SSH_KEYS);

  const formClass = "relative py-10 pt-2 border-b border-[#F2F2F2] sm:py-12 sm:pt-4"
  const headingClass = "text-flow-gray-9 font-medium text-lg mb-2 sm:text-xl sm:mb-3";
  const rowClass = "flex gap-x-10 flex-wrap gap-y-5 sm:gap-y-6";
  const cellClass = "w-full max-w-sm sm:max-w-xs";
  const labelClass = "text-flow-gray-9 font-medium text-sm block mb-1.5";
  const inputClass = "block bg-flow-gray-2 text-flow-blue-7 rounded font-medium text-base px-3 py-1.5 w-full sm:text-lg";
  const buttonClass = "btn-primary mt-6 sm:mt-8";

  return (
    <Base>
      <h1>Settings</h1>
      <Tabs labels={["User Details", "Groups", "Account Management"]} selected={tab} setSelected={setTab} />
      {tab === "User Details" && (
        <div>
          <UserDetailsForm
            formClass={formClass} headingClass={headingClass} rowClass={rowClass}
            cellClass={cellClass} labelClass={labelClass} inputClass={inputClass}
            buttonClass={buttonClass} settings={settings} setSettings={setSettings}
          />
          <PasswordForm
            formClass={formClass} headingClass={headingClass} rowClass={rowClass}
            cellClass={cellClass} labelClass={labelClass} inputClass={inputClass}
            buttonClass={buttonClass}
          />
          {showPrivateKeyForm && (
            <PrivateKeyUploader
              settings={settings} setSettings={setSettings}
              formClass={formClass} headingClass={headingClass} rowClass={rowClass}
              cellClass={cellClass} labelClass={labelClass} inputClass={inputClass}
              buttonClass={buttonClass}
            />
          )}
          <UserImageForm
            formClass={formClass} headingClass={headingClass}
            buttonClass={buttonClass} settings={settings} setSettings={setSettings}
          />
        </div>
      )}
      {tab === "Groups" && (
        <div className="">
          <Link className="btn-primary py-1 my-4 max-w-xs cursor-pointer sm:w-fit" to="/groups/new/">
            Create a Group
          </Link>
          {managedGroups.length > 0 && (
            <GroupSummaries
              groups={managedGroups} title="Groups you run"
              formClass={formClass} headingClass={headingClass}
              settings={settings} setSettings={setSettings}
            />
          )}
          {otherGroups.length > 0 && (
            <GroupSummaries
              groups={otherGroups} title="Groups you're a member of"
              formClass={formClass} headingClass={headingClass}
              settings={settings} setSettings={setSettings}
            />
          )}
        </div>
      )}
      {tab === "Account Management" && (
        <AccountInfo settings={settings} formClass={formClass} headingClass={headingClass} />
      )}
    </Base>
  );
};

SettingsPage.propTypes = {
  
};

export default SettingsPage;